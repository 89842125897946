import swal from 'sweetalert2/dist/sweetalert2.js';
import scope from '../scope';

var notCloseOnLoading = function(){
    if(scope.preventUserCloseModal) {
        return false;
    }

    return !swal.isLoading();
};

var mySwal = swal.mixin({
    showCloseButton: true,
    focusCancel: false,
    didOpen: function(wrapper) {
        $('body').trigger('focus');

        setTimeout(function(){
            $(wrapper).trigger('focus');
        }, 500);
    },
    willClose: function() {
        if(scope.preventUserCloseModal) {
            window.location = '/';
            return false;
        }

        scope.login_redirect = null;
    },
    allowOutsideClick: notCloseOnLoading,
    allowEscapeKey: notCloseOnLoading,
});

export default mySwal;